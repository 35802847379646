import dayjs from "dayjs";
import { defMenu } from "./constant";

/**
 * 深拷贝对象，可以正确序列化日期
 * @param {*} obj
 */
export const DEEP_CLONE = function (obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // 判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          // 深拷贝日期类型
          if (obj[key] instanceof Date) {
            objClone[key] = new Date(obj[key].valueOf());
            // console.log('deepClone', objClone[key])
          } else {
            objClone[key] = DEEP_CLONE(obj[key]);
          }
        } else {
          // 如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
};

export function sortMenus(menuList) {
  let meunMap = {};
  let newMenus = [];
  menuList.forEach((item) => {
    if (!meunMap[item.routerName]) {
      if (defMenu.routerName === item.routerName) {
        newMenus.unshift(item);
      } else {
        newMenus.push(item);
      }
      meunMap[item.routerName] = 1;
    }
  });
  return newMenus;
}

export function getTimeRange(range, type, format) {
  format = format || "YYYY-MM-DD HH:mm:ss";
  return [dayjs().subtract(range, type).format(format), dayjs().format(format)];
}

export function formatNumber(num, fixedNum) {
  if (!num || isNaN(Number(num))) {
    return num;
  }
  if (fixedNum > 0) {
    return Number(num).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return Number(num).toLocaleString();
}

export function setVersionConsole() {
  const pkgInfo = require("../../../../version-info.json");
  console.log(`%cversion: ${pkgInfo.version}`, 'color: rgb(30,152,255);padding:6px;');
}

//test
export const getCount = state => {
    return state.count
}

export const getCurTabName = state => {
    return state.curTabMenuName;
}

export const userInfo = state => {
    return state.userInfo;
}

export const accountFillInfo = state => {
    return state.accountFillInfo;
}
export const txDetail = state => {
    return state.txDetail;
}
export const assetDetail = state => {
    return state.assetDetail;
}
export const channelDetail = state => {
    return state.channelDetail;
} 
export const detail = (state,name) => {
    return state[name];
}
export const voucherDesc = (state,name) => {
    return state.voucherDesc;
}
export const deviceInfo = (state,name) => {
    return state.deviceInfo;
}

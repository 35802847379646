export function numberFormat(num) {
  return num ? num.toFixed(4) : '0.0000';
}

export function priceFormatYuan(num) {
  num = parseFloat(num);
  return num ? num.toFixed(2) : '0.00';
}

export function priceFormatWanYuan(num) {
  num = parseFloat(num);
  return num ? num.toFixed(4) : '0.0000';
}

export const dateFormat = (date, Str) => {
  let newDate = new Date(date);
  let Y = newDate.getFullYear();
  let M = (newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1);
  let D = newDate.getDate() < 10 ? ('0' + newDate.getDate()) : newDate.getDate();
  let H = (newDate.getHours() < 10 ? ('0' + newDate.getHours()) : newDate.getHours());
  let Mm = (newDate.getMinutes() < 10 ? ('0' + newDate.getMinutes()) : newDate.getMinutes());
  let S = newDate.getSeconds() < 10 ? ('0' + newDate.getSeconds()) : newDate.getSeconds();
  let strType = {
    d: Y + "-" + M + "-" + D,
    md: M + "-" + D,
    t: H + ":" + Mm,
    dt: M + "-" + D + " " + H + ":" + Mm,
  }
  return strType[Str] || Y + "-" + M + "-" + D + " " + H + ":" + Mm + ":" + S;

}

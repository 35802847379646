import ErrorRoute from "@/views/error";
import Vue from "vue";
import Router from "vue-router";
//路由导航冗余报错（路由重复）
// const originalReplace = Router.prototype.replace;
// Router.prototype.replace = function replace(location) {
//   return new Promise(()=>{
//     originalReplace.call(this, location).catch(err => err);
//   })
// };
Vue.use(Router);
const routes = [
  {
    name: "index",
    path: "/",
    redirect: {
      name: "MainLayout"
    },
    component: resolve => require(["@/views/index"], resolve),
    meta: { requiresAuth: true },
    children: [
      {
        name: "noAuth",
        path: "/noAuth",
        component: resolve => require(["@/views/noAuth"], resolve)
      },

      {
        name: "MainLayout",
        path: "/main/layout",
        redirect: {
          name: "Overview"
        },
        component: resolve => require(["@/views/MainLayout"], resolve),
        children: [
          {
            name: "Overview",
            path: "/overview",
            meta: { tabName: "Overview" },
            component: resolve =>
              require(["@/views/digitalAssetsOpenNetwork/Overview"], resolve)
          },
          {
            name: "QueryVue",
            path: "/query",
            meta: { tabName: "QueryVue" },
            component: resolve =>
              require(["@/views/digitalAssetsOpenNetwork/QueryVue"], resolve)
          },
          {
            name: "Filing",
            path: "/filing",
            meta: { tabName: "Filing" },
            component: resolve =>
              require(["@/views/customerManagement/Filing"], resolve)
          },
          {
            name: "FilingAdd",
            path: "/filingAdd",
            meta: { tabName: "Filing" },
            component: resolve =>
              require(["@/views/customerManagement/FilingAdd"], resolve)
          },
          {
            name: "ChannelAccount",
            path: "/channelAccount",
            meta: { tabName: "Filing" },
            component: resolve =>
              require(["@/views/customerManagement/ChannelAccount"], resolve)
          },
          {
            name: "Account",
            path: "/account",
            meta: { tabName: "Account" },
            component: resolve =>
              require(["@/views/customerManagement/Account"], resolve)
          },
          {
            name: "AddAccount",
            path: "/addAccount",
            meta: { tabName: "Account" },
            component: resolve =>
              require(["@/views/customerManagement/AddAccount"], resolve)
          },
          {
            name: "EnterpriseAuth",
            path: "/enterpriseAuth",
            meta: { tabName: "Filing" },
            component: resolve =>
              require(["@/views/customerManagement/EnterpriseAuth"], resolve)
          },
          {
            name: "RealNameAuth",
            path: "/realNameAuth",
            meta: { tabName: "RealNameAuth" },
            component: resolve =>
              require(["@/views/customerManagement/RealNameAuth"], resolve)
          },
          {
            name: "Voucher",
            path: "/Voucher",
            meta: { tabName: "Voucher" },
            component: resolve =>
              require(["@/views/tools/Voucher.vue"], resolve)
          },
          {
            name: "AddVoucher",
            path: "/addVoucher",
            meta: { tabName: "AddVoucher" },
            component: resolve =>
              require(["@/views/tools/AddVoucher.vue"], resolve)
          }
        ]
      }
    ]
  },
  {
    path: "/error",
    component: ErrorRoute,
    name: "error",
    meta: { requiresAuth: false },
    hidden: true
  },
  {
    name: "login",
    path: "/login",
    component: resolve => require(["@/views/login"], resolve)
  }
];

export default routes;

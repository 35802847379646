/**
 * 登录相关的api
 */
// import * as API from "@/utils/request";
import * as API from './request'
const comUrl = "/v1/admin/";
export default {
	loginIn: params => {
		return API.post(`/api/auth/login`, params);
	},
	loginOut: params => {
		return API.post(`/api/auth/logout`, params);
	},
	baseUserInfo: params => {
		return API.get(`${comUrl}user/`, params);
	}

}

import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)

const newLocal = 'ProductIndex'

const router = new Router({
  mode: 'history',
  routes
});

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
  // return VueRouterPush.call(this, to);
}

export default router
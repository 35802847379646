<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "./scss/_common.scss";
@import "./scss/_icons.scss";

.el-select {
  width: 100% !important;
}
</style>
<script>
import { mapActions } from "vuex";
import { setVersionConsole } from "@/util/common.js";

export default {
  name: "app",
  methods: {
    ...mapActions(["setUserInfo"])
  },
  created() {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
    if(Object.keys(userInfo).length > 0) {
      this.setUserInfo(userInfo);
    }
  },
  mounted() {
    setVersionConsole();
  }
};
</script>


import Vue from "vue";
import ElementUI from "element-ui";
import "./assets/theme/index.css";
// import VeeValidate from "vee-validate";
import App from "./App";
import router from "./router";
import * as filters from "./filter";
import * as API from "./api/request";
import tableHeader from "@/util/tableHeader";
import * as ComFunc from "@/util/common";
import table from "@/components/baseTable/table";
import { COMMON_MESSAGE } from "@/util/constant";
Vue.prototype.tableHeader = tableHeader;
Vue.prototype.$EventBus = new Vue();
//vuex
import store from "./vuex/store";
import Vuex from "vuex";
import VueClipboard from "vue-clipboard2";

// register global utility filters.
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$API = API;
Vue.prototype.$ComFunc = ComFunc;
Vue.prototype.$CommonMsg = COMMON_MESSAGE;

Vue.config.productionTip = false;

//验证组件
// Validator.addLocale(VeeValidateMessagesCN);
// Vue.use(VeeValidate, {
//   // locale: 'zh_CN',
//   fieldsBagName: "VeeFields",
// });

Vue.use(Vuex);
Vue.use(table);
Vue.use(ElementUI);
Vue.use(VueClipboard);

//路由之前做的操作
router.beforeEach((to, from, next) => {
  let { requiresAuth = true } = to.meta;
  let isLogin = false;
  let $body = document.getElementsByTagName("body")[0];
  let initRoleId = to.meta.roleId;
  let roleId, roleName;

  let tokenDate = sessionStorage.getItem("tokenDate");
  let tokenValid = new Date() - new Date(tokenDate) < 1000 * 60 * 60 * 12;

  if (sessionStorage.getItem("userInfo")) {
    isLogin = true;
    roleId = sessionStorage.getItem("userInfo").split("_")[1];
    roleName = sessionStorage.getItem("userInfo").split("_")[2];
  } else {
    isLogin = false;
  }

  isLogin = tokenValid ? isLogin : false;

  /**
   * 根据roleId权限控制路由跳转
   * 运营后台管理 roleId ===0 情况下 才能进行 运营后台操作
   * */

  // if(requiresAuth && isLogin && roleId !==  initRoleId && to.path !== '/noAuth'){
  //   return next({
  //     path: '/noAuth',
  //     query: { roleName:roleName }
  //   })
  // }

  /**需访问权限&&已经登录&&不是登录页* */
  if (requiresAuth && !isLogin && to.path !== "/login") {
    return next({
      path: "/login",
    });
  }

  /**非登录页面body样式控制* */
  if (to.path !== "/login") {
    $body.classList.remove("login");
    // store.commit('SHOWFULLLOAD', true)
  }
  next();
});

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.current.fullPath;
  if (isChunkLoadFailed) {
    // console.log("路由报错了！")
    // router.replace(targetPath);
    location.reload();
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

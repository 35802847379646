import axios from "axios";
import { AES, enc } from "crypto-js"; //超时时间
import store from "@/vuex/store";
import { MessageBox } from "element-ui";

let HAS_TOKEN_BOX = false;

// <-------------------------- 请求封装 -------------------------------->

/**
 * patch封装
 */
export function patch(api, data = {}, method = "PATCH", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * put封装
 */
export function put(api, data = {}, method = "PUT", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * post封装
 */
export function post(api, data = {}, method = "POST", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * get封装
 */
export function get(api, data = {}, method = "GET", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * delete封装
 */
export function del(api, data = {}, method = "DELETE", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

export function getHeader() {
  let header = {};
  // let userInfor = localStorage.getItem('perms')
  // console.log(userInfor);
  // if (userInfor) {
  //   userInfor = AES.decrypt(userInfor, "hteAdminWeb").toString(enc.Utf8);
  //   header = {
  //     'Content-Type': 'application/json',
  //     // "Authorization":"bearer " + userInfor,
  //     "Authorization": store.getters.userInfo.authorization
  //   }
  // }
  header = {
    "Content-Type": "application/json",
    // "Authorization":"bearer " + userInfor,
    Authorization: store.getters.userInfo.authorization
  };
  return header;
}

const baseUrl = `${location.protocol}//${process.env.VUE_APP_BASE_URL}`;
store.getters.deviceInfo.baseUrl = baseUrl;

// 添加响应拦截器
// axios.interceptors.response.use(
//   response => {
//     console.log(response)
//     //与后台约定好 cookie 过期处理
//     // if (response.data.code === -1) {
//     //   router.replace({
//     //     path: '/login'
//     //   })
//     //   localStorage.clear()
//     // }
//     return response;
//   },
//   error => {
//     console.log(error)
//     if (error.response) {
//     //   switch (error.response.status) {
//     //     case 500:
//     //       //临时处理跳转 后台错误数据结构未定义
//     //       alert(error.response.data.msg)

//     //       router.go(0)
//     //       break;
//     //     case 502:
//     //       //   sessionStorage.removeItem('userInfo');
//     //       router.replace({
//     //         path: '/error',
//     //         query: {
//     //           status: 502
//     //         }
//     //       })
//     //       break;
//     //     case 504:
//     //       router.replace({
//     //         path: '/error',
//     //         query: {
//     //           status: 504
//     //         }
//     //       })
//     //       break;
//     //     case 403:
//     //       router.replace({
//     //         path: '/error',
//     //         query: {
//     //           status: 403
//     //         }
//     //       })
//     //       break;
//     //     case 404:
//     //       router.replace({
//     //         path: '/error',
//     //         query: {
//     //           status: 404
//     //         }
//     //       })
//     //       break;
//     //   }
//     // } else {
//       // router.replace({
//       //   path: '/login',
//       // })
//     }
//     return Promise.reject(error);
// });

/**
 * 统一的请求封装，
 *
 * @param {String} api 请求的api地址
 * @param {JSON} params 请求参数或者请求体，具体参考文档
 * @param {String} methods 请求方式，默认POST/GET
 * @param {JSON} header 请求的头部
 * @param {boolean} [loading=true] 是否显示loading，默认true
 */
export function request(api, params = {}, method, header, loading = true) {
  return new Promise((resolve, reject) => {
    let data =
      method.toLocaleLowerCase() === "get" ||
      method.toLocaleLowerCase() === "delete"
        ? "params"
        : "data";
        
    const fullUrl = baseUrl + api;
    // 请求
    axios({
       url: fullUrl,
      [data]: params,
      method: method,
      headers: header
    })
      .then(function(res) {
        excuteToken(res);
        resolve(res);
      })
      .catch(ret => {
        reject(ret);
      });
  });
}

const excuteToken = ({data}) => {
  const code = data.code;
  const tokenCodeMap={
    703:'登录态已过期，请重新登录',
    705:'您已在其他地方登录'
  }
  
  if(!([703,705].includes(code))|| HAS_TOKEN_BOX) {
    return;
  }
  HAS_TOKEN_BOX = true;
  MessageBox.confirm(tokenCodeMap[code], "提示", {
    type: "warning",
    showCancelButton: false
  }).finally(() => {
    localStorage.clear();
    sessionStorage.removeItem("userInfo");
    location.reload();
  });
};

export const downLoadPDF = (filename, url) => {
  // let userInfor = sessionStorage.getItem("userInfo");
  return new Promise((resolve, reject) => {
    axios({
      headers: getHeader(),
      method: "get",
      url: url, // 请求地址
      responseType: "blob" // 表明返回服务器返回的数据类型
    }).then(
      response => {
        resolve(response.data);
        let blob = new Blob([response.data], {
          type: "application/pdf"
        });
        let fileName = filename;
        // console.log('文件名称', fileName);
        if (window.navigator.msSaveOrOpenBlob) {
          // console.log(2)
          navigator.msSaveBlob(blob, fileName);
        } else {
          // console.log(3)
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          //释放内存
          window.URL.revokeObjectURL(link.href);
        }
      },
      err => {
        reject(err);
      }
    );
  });
};

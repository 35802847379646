import {
  accountStatusMap,
  identifyStatusMap,
  resultStatusMap,
  exchangeStatusMap,
  enterpriseIdentifyMap,
  enterpriseAuthMap
} from "./constant";
export default {
  overview: {
    tableHeader: [
      {
        label: "渠道名称/编号",
        prop: "appNo",
        isBtn: false,
      },
      {
        label: "当前请求总数",
        prop: "requestNum",
        isBtn: false,
      },
      {
        label: "当前交易总数",
        prop: "successNum",
        isBtn: false,
      },
      {
        label: "当前待处理",
        prop: "pendingNum",
        isBtn: false,
      },
    ],
  },
  //客户备案
  customRecord: {
    tableHeader: [
      {
        label: "客户编号",
        prop: "numid",
        isBtn: false,
      },
      {
        label: "客户名称",
        prop: "name",
        isBtn: false,
      },
      {
        label: "机构代码",
        prop: "creditCode",
        isBtn: false,
        minWidth: 180,
      },
      {
        label: "渠道编号",
        prop: "appId",
        isBtn: false,
      },
      {
        label: "工作台账号",
        prop: "workbenchAccount",
        isBtn: false,
      },
      {
        label: "区块链账户",
        prop: "account",
        minWidth: 350,
        isBtn: false,
      },
      {
        label: "注册时间",
        prop: "createdTime",
        type: "date",
        minWidth: 90,
        isBtn: false,
      },
      {
        label: "状态",
        prop: "accessStatus",
        type: "status",
        statusMap: {
          false: {
            text: "断开",
            class: "switch-off",
          },
          true: {
            text: "接入",
            class: "switch-on",
          },
        },
        isBtn: false,
      },
      {
        label: "认证状态",
        tagProps: ["realNameStatus", "auditStatus"],
        statusMap: identifyStatusMap,
        type: "tags",
        isBtn: false,
      },
      {
        label: "更新时间",
        prop: "updatedTime",
        minWidth: 90,
        isBtn: false,
        type: "date",
      },
    ],
  },
  //渠道请求
  channelAccount: {
    tableHeader: [
      {
        label: "普通用户标识",
        prop: "No",
        isBtn: false,
      },
      {
        label: "区块链账户",
        prop: "account",
        isBtn: false,
      },
      {
        label: "创建时间",
        prop: "createdTime",
        type: "date",
        minWidth: 90,
        isBtn: false,
      },
      {
        label: "账户状态",
        prop: "accountStatus",
        type: "status",
        width: 100,
        statusMap: {
          1: {
            text: "锁定",
            class: "switch-off",
          },
          0: {
            text: "正常",
            class: "switch-on",
          },
        },
        isBtn: false,
      },
    ],
  },
  //客户账户
  account: {
    tableHeader: [
      {
        label: "客户编号",
        prop: "numid",
        isBtn: false,
      },
      {
        label: "客户名称",
        prop: "shopName",
        isBtn: false,
      },
      {
        label: "机构代码",
        prop: "creditCode",
        isBtn: false,
        width: "150",
      },
      {
        label: "渠道编号",
        prop: "appId",
        isBtn: false,
      },
      {
        label: "账户方案",
        prop: "chargingModel",
        type: "status",
        width: 120,
        statusMap: {
          2: {
            noIcon: true,
            text: "按周期计费",
          },
          3: {
            noIcon: true,
            text: "按燃料消耗计费",
          },
        },
        isBtn: false,
      },
      {
        label: "使用情况",
        type: "button",
        class: "check",
        buttonType: "check",
        minWidth: 120,
        btnTxt: "查看",
        isBtn: true,
      },
      {
        label: "创建时间",
        prop: "createdTime",
        type: "date",
        minWidth: 100,
        isBtn: false,
      },
      {
        label: "修改时间",
        prop: "updatedTime",
        type: "date",
        minWidth: 100,
        isBtn: false,
      },
    ],
  },
  //交易流转
  transactionFlow: {
    tableHeader: [
      {
        label: "请求编号",
        prop: "requestId",
        isBtn: false,
      },
      {
        label: "渠道编号",
        prop: "appId",
        isBtn: false,
      },
      {
        label: "交易哈希",
        prop: "txHash",
        isBtn: false,
      },
      {
        label: "通证编号",
        prop: "creditCode",
        isBtn: false,
      },
      {
        label: "合约地址",
        prop: "contractAddress",
        isBtn: false,
      },
      {
        label: "请求时间",
        prop: "createdTime",
        isBtn: false,
      },
      {
        label: "上链时间",
        prop: "blockTime",
        isBtn: false,
      },
      {
        label: "发起方",
        prop: "fromAccount",
        isBtn: false,
      },
      {
        label: "接收方",
        prop: "toAccount",
        isBtn: false,
      },
      {
        label: "区块高度",
        prop: "blockHeight",
        isBtn: false,
      },
      {
        label: "状态",
        prop: "status",
        type: "status",
        isBtn: false,
        statusMap: {
          0: {
            text: "等待中",
            class: "yellow",
          },
          1: {
            text: "上链成功",
            class: "switch-on",
          },
          2: {
            text: "上链失败",
            class: "switch-off",
          },
        },
      },
    ],
  },

  // 资产发行
  assetIssuance: {
    tableHeader: [
      {
        label: "资产编号",
        prop: "assetId",
        isBtn: false,
      },
      {
        label: "渠道编号",
        prop: "appId",
        isBtn: false,
      },
      {
        label: "交易哈希",
        prop: "txHash",
        minWidth: 350,
        isBtn: false,
      },
      {
        label: "区块高度",
        prop: "blockHeight",
        isBtn: false,
      },
      {
        label: "合约地址",
        prop: "contractAddress",
        minWidth: 350,
        isBtn: false,
      },
      {
        label: "份额上限",
        prop: "amount",
        isBtn: false,
      },
      {
        label: "已生成份额",
        prop: "generateAmount",
        isBtn: false,
      },
      {
        label: "请求时间",
        prop: "createdTime",
        isBtn: false,
        width: "150",
      },
      {
        label: "上链时间",
        prop: "blockTime",
        isBtn: false,
        width: "150",
      },
      {
        label: "状态",
        prop: "status",
        type: "status",
        isBtn: false,
        width: "150",
        statusMap: {
          0: {
            text: "初始化",
            class: "blue",
          },
          1: {
            text: "发行成功",
            class: "switch-on",
          },
          2: {
            text: "失败",
            class: "switch-off",
          },
          3: {
            text: "发行中",
            class: "yellow",
          },
        },
      },
    ],
  },
  //渠道请求
  channelRequest: {
    tableHeader: [
      {
        label: "请求时间",
        prop: "createdTime",
        isBtn: false,
      },
      {
        label: "渠道编号",
        prop: "appId",
        isBtn: false,
      },
      {
        label: "接口路径",
        prop: "operateType",
        type: "status",
        statusMap: {
          0: {
            text: "新建",
          },
          1: {
            text: "修改",
          },
          2: {
            text: "发行",
          },
          3: {
            text: "授予",
          },
          4: {
            text: "转让",
          },
        },
        isBtn: false,
      },
      {
        label: "请求编号",
        prop: "requestId",
        isBtn: false,
      },
      {
        label: "响应编号",
        prop: "operationId",
        isBtn: false,
      },
    ],
  },
  Authorization: {
    tableHeader: [
      {
        label: "序号",
        prop: "No",
        isBtn: false,
        width: 80,
      },
      {
        label: "授权主体名称",
        prop: "issuerName",
        isBtn: false,
        width: 130,
      },
      {
        label: "授权主体数字身份ID",
        prop: "issuerDigiId",
        isBtn: false,
        width: 180,
      },
      {
        label: "被授权主体名称",
        prop: "receiverName",
        isBtn: false,
        width: 150,
      },
      {
        label: "被授权主体数字身份ID",
        prop: "receiverDigiId",
        isBtn: false,
        width: 200,
      },
      {
        label: "授权交易场景",
        prop: "scenario",
        isBtn: false,
        width: 140,
      },
      {
        label: "授权数据",
        prop: "dataScope",
        isBtn: false,
        width: "100",
      },
      {
        label: "授权有效期",
        prop: "validityPeriod",
        isBtn: false,
        width: "110",
      },
      {
        label: "DAPP系统",
        prop: "dappSrc",
        isBtn: false,
        width: "110",
      },
      {
        label: "授权方向",
        prop: "authOption",
        isBtn: false,
        width: "100",
      },
      {
        label: "授权交易时间",
        prop: "createTime",
        isBtn: false,
        width: "130",
      },
      {
        label: "操作",
        fixed: "right",
        prop: "isCertificate",
        operation: [
          {
            name: "凭证查看",
            active: "viewCertificate1",
            icon: "icon-pingzheng.svg",
          },
          {
            name: "存证查看",
            active: "viewCertificate2",
            icon: "icon-cunzheng.svg",
          },
        ],
        isBtn: true,
        width: 220,
      },
    ],
  },
  UserSalesmanAllocation: {
    tableHeader: [
      {
        label: "序号",
        prop: "No",
        isBtn: false,
        width: 80,
      },
      {
        label: "用户名",
        prop: "name",
        isBtn: false,
        width: 130,
      },
      {
        label: "客户姓名",
        prop: "fullName",
        isBtn: false,
        width: 130,
      },
      {
        label: "公司名称",
        prop: "companyName",
        isBtn: false,
        width: 130,
      },
      {
        label: "联系电话",
        prop: "phone",
        isBtn: false,
        width: 130,
      },
      {
        label: "业务员姓名",
        prop: "salesman",
        isBtn: false,
        width: 130,
      },
      {
        label: "所属渠道",
        prop: "channelName",
        isBtn: false,
        width: 130,
      },
      {
        label: "创建时间",
        prop: "createTime",
        isBtn: false,
      },
      {
        label: "最近登录时间",
        prop: "lastLoginTime",
        isBtn: false,
      },
      {
        label: "操作",
        fixed: "right",
        prop: "",
        operation: [
          {
            name: "分配",
            active: "view",
            disableStatus: "editDisabled",
          },
        ],
        isBtn: true,
      },
    ],
  },
  OrderQuery: {
    tableHeader: [
      {
        label: "发起时间",
        prop: "createdTime",
        type: "date",
      },
      {
        label: "订单编号",
        prop: "orderId",
      },
      {
        label: "工作台账号",
        prop: "workbenchAccount",
      },
      {
        label: "支付金额",
        prop: "amount",
        type: "money",
      },
      {
        label: "支付状态",
        prop: "status",
        type: "status",
        statusMap: resultStatusMap,
      },
      {
        label: "支付时间",
        prop: "payTime",
        type: "date",
      },
      {
        label: "支付编号",
        prop: "bizId",
      },
      {
        label: "支付银行编号",
        prop: "bankId",
      },
      {
        label: "支付银行卡号",
        prop: "bankCardNo"
      }
    ],
  },
  authOrAudit: {
    tableHeader: [
      {
        label: "工作台账号",
        prop: "workbenchAccount",
      },
      {
        label: "工作台信息",
        type: "button",
        class: "check",
        buttonType: "work",
        minWidth: 120,
        btnTxt: "查看",
        isBtn: true,
      },
      {
        label: "实名认证信息",
        type: "button",
        class: "check",
        buttonType: "realName",
        minWidth: 120,
        btnTxt: "查看",
        isBtn: true,
      },
      {
        label: "企业认证信息",
        type: "button",
        class: "check",
        buttonType: "company",
        minWidth: 120,
        btnTxt: "查看",
        isBtn: true,
      },
      {
        label: "企业认证状态",
        prop: "auditStatus",
        statusMap: enterpriseAuthMap,
        type: "status"
      },
      {
        label: "提交认证时间",
        prop: "auditCommitTime",
        type: "date"
      },
      {
        label: "认证类别",
        prop: "workbenchUserRole",
        statusMap: enterpriseIdentifyMap,
        type: "status"
      },
      {
        label: "人工审核时间",
        prop: "manualAuditTime",
        type: "date"
      },
    ],
  },
  voucher: {
    tableHeader: [
      {
        label: "兑换券码",
        prop: "voucherCode",
      },
      {
        label: "燃料额度",
        prop: "amount",
        type: "money",
      },
      {
        label: "指定渠道",
        prop: "shopName",
      },
      {
        label: "创建时间",
        prop: "createdTime",
        type: "date",
      },
      {
        label: "兑换状态",
        prop: "status",
        statusMap: exchangeStatusMap,
        type: "status",
      },
      {
        label: "兑换账号",
        prop: "usedAccount",
      },
      {
        label: "兑换时间",
        prop: "usedTime",
        type: "date",
      },
    ],
  },
};

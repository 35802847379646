<template>
  <el-row>
    <el-col :span="24" class="tablelist">
      <el-table
        class="table_details"
        ref="multipleTable"
        :data="baseData"
        style="width: 100%"
        :highlight-current-row="highlight"
        v-loading="listLoading"
        @row-click="rowClick"
        @row-dblclick="rowDbClick"
        @cell-dblclick="dblhandleCurrentChange"
        element-loading-text="获取中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.5)"
        @selection-change="handleSelectionChange"
        :row-style="rowStyle"
        :header-row-style="headerRowStyle"
      >
        <el-table-column
          type="selection"
          v-if="isShow"
          :selectable="selectable"
          width="55"
        ></el-table-column>

        <el-table-column
          v-for="item in tableHeader"
          :key="item.label"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :min-width="item.minWidth || 150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <!--status渲染列表状态  -->
            <span
              v-if="item.type === 'status'"
              class="switch-status"
              :class="
                item.statusMap[scope.row[`${item.prop}`]]
                  ? item.statusMap[scope.row[`${item.prop}`]]['class']
                  : ''
              "
            >
              <i
                v-if="
                  item.statusMap[scope.row[`${item.prop}`]] &&
                  !item.statusMap[scope.row[`${item.prop}`]]['noIcon']
                "
                class="icon"
              ></i>
              <span class="text">{{
                item.statusMap[scope.row[`${item.prop}`]]
                  ? item.statusMap[scope.row[`${item.prop}`]]["text"]
                  : ""
              }}</span>
            </span>
            <!--button渲染列表中间部分可操作  -->
            <span v-else-if="item.type === 'button'" :class="[item.class]">
              <span
                @click="
                  onClickBtn(
                    Object.assign({ buttonType: item.buttonType }, scope.row)
                  )
                "
                >{{ item.btnTxt }}</span
              >
            </span>
            <span v-else-if="item.type === 'date'">
              {{ formatTime(scope.row[`${item.prop}`]) }}
            </span>
            <span v-else-if="item.type === 'tags'">
              <span
                v-for="(tag, i) in item.tagProps"
                :key="i"
                class="tag"
                :class="
                  item.statusMap[tag][scope.row[tag]] &&
                  item.statusMap[tag][scope.row[tag]].class
                "
              >
                {{
                  item.statusMap[tag][scope.row[tag]] &&
                  item.statusMap[tag][scope.row[tag]].text
                }}
              </span>
            </span>
            <span v-else-if="item.type === 'money'">{{
              formatNumber(scope.row[`${item.prop}`], 2)
            }}</span>
            <span v-else>{{ scope.row[`${item.prop}`] }}</span>
          </template>
        </el-table-column>
        <!--插入最右边操作  -->
        <slot></slot>
      </el-table>
    </el-col>
    <el-col :span="24" class="toolbar" v-if="isShowPagination">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPageNum"
        :page-size="pageSize"
        layout="total,prev, pager, next, jumper"
        :total="total"
        style="float: right"
      ></el-pagination>
    </el-col>
  </el-row>
</template>
<script>
import dayjs from "dayjs";
import { formatNumber } from "@/util/common";

export default {
  props: {
    baseData: Array,
    tableHeader: Array,
    currentPage: Number,
    total: Number,
    listLoading: Boolean,
    isShow: Boolean,
    isShowPagination: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    oprateWith: String,
    thHeight: String,
    tdHeight: String,
  },
  data() {
    return {
      currentPageNum: this.currentPage,
      pageSize: 10,
      // productStatusMap,
    };
  },
  methods: {
    formatNumber,
    onClickBtn(data) {
      this.$emit("onClickBtn", data);
    },
    rowStyle: function (row, index) {
      if (row.rowIndex % 2) {
        return {
          backgroundColor: "#FBFBFB",
          height: this.tdHeight ? this.tdHeight : "56px",
        };
      }
      return {
        height: this.tdHeight ? this.tdHeight : "56px",
      };
    },
    headerRowStyle(row, index) {
      return {
        height: this.tdHeight ? this.tdHeight : "64px",
      };
    },
    handleClick(data, action, index, name) {
      this.$emit(action + "-btn", data, index, name);
    },
    handleCurrentChange(val) {
      // console.log("触发", val);
      this.$emit("handleCurrent", val);
    },
    rowClick(row, column, cell, event) {
      this.$emit("row-click", row);
    },
    rowDbClick(row, column, cell, event) {
      this.$emit("row-dblclick", row);
    },
    dblhandleCurrentChange(row, column, cell, event) {
      this.$emit("dbHandle-btn", row);
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    clearSelect() {
      this.$refs.multipleTable.clearSelection();
    },
    //复选框控制
    selectable(row, index) {
      // if (row.status == 1 || row.status == 3) {
      //   return true;
      // } else {
      //   return false;
      // }
      return true;
    },

    /**
     * 获取icon图片
     */
    getIconSrc(src) {
      return require("@/assets/icons/" + src);
    },
    /**
     * 设置状态样式
     */
    setStatusStyle(item, label) {
      label = this.trfLabel(item.prop, label);
      let classMap = {
        正常: "success",
        关闭: "closed",
        冻结: "frozen",
        锁定: "locked",
        已上架: "success",
        已下架: "closed",
        申请中: "normal",
        已申请: "locked",
        已放款: "success",
        已拒绝: "closed",
        未上架: "locked",
        已上架: "success",
        已下架: "closed",
      };
      return classMap[label];
    },
    /**
     * 转换标签
     */
    trfLabel(prop, label) {
      let labelMap = {
        APPLIED: "已申请",
        LOANED: "已放款",
        REFUSED: "已拒绝",
        NEW: "未上架",
        RELEASE: "已上架",
        RECALL: "已下架",
        APPLYING: "申请中",
      };
      if (labelMap.hasOwnProperty(label)) {
        label = labelMap[label];
      }
      if (prop == "documentSizes") label += "M";
      return label;
    },
    /**
     * 转换按钮的标签
     */
    trfBtnLabel(item, btn) {
      let name = btn.name;
      if (btn.hasOwnProperty("change")) {
        name = item[btn.change] == "RELEASE" ? "下架" : "上架";
      }
      return name;
    },
    formatTime(time) {
      if(!time) {
        return '-';
      }
      return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {},
  watch: {
    currentPage: {
      handler(newVal, oldVal) {
        this.currentPageNum = newVal;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  width: 10px;
  height: 10px;
}

.switch-status {
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  .icon,
  .text {
    vertical-align: middle;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &.blue {
    .icon {
      background-color: #337cff;
    }
    .text {
      color: #337cff;
    }
  }
  &.yellow {
    .icon {
      background-color: #ff9428;
    }
    .text {
      color: #ff9428;
    }
  }
  &.switch-on {
    .icon {
      background-color: #02bb75;
    }
    .text {
      color: #02bb75;
    }
  }
  &.switch-off {
    .icon {
      background-color: #ff3f4b;
    }
    .text {
      color: #ff3f4b;
    }
  }

  &.wating {
    color: #ff9428;
  }
  &.success {
    color: #02bb75;
  }
  &.fail {
    color: #ff3f4b;
  }
}
.tag {
  padding: 6px 10px;
  color: #fff;
  border-radius: 6px;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.success {
    background-color: #337cff;
  }
}
</style>

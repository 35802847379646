export const navList = [
  {
    name: "数字资产开放网络",
    id: "11",
    routerName: "netWork",
    menuIndex: "1",
    children: [
      {
        id: "1",
        name: "概览",
        routerName: "Overview",
        canBeDelete: true,
        menuIndex: "1-1"
      },
      {
        id: "2",
        name: "查询",
        routerName: "QueryVue",
        menuIndex: "1-2"
      }
    ]
  },
  {
    id: "22",
    name: "客户管理",
    routerName: "manager",
    menuIndex: "2",
    children: [
      {
        id: "3",
        name: "客户备案",
        routerName: "Filing",
        menuIndex: "2-1"
      },
      {
        id: "4",
        name: "客户账户",
        routerName: "Account",
        menuIndex: "2-2"
      }
    ]
  },
  {
    id: "33",
    name: "工具管理",
    routerName: "toolsManager",
    menuIndex: "3",
    children: [
      {
        id: "5",
        name: "兑换券工具",
        routerName: "Voucher",
        menuIndex: "3-1"
      },
    ]
  }
];
export const accountStatusMap = {
  access: "接入"
};

export const docStatusMap = {
  NEW: "未上架",
  RELEASE: "已上架",
  RECALL: "已下架"
};

export const assetNameMapCode = {
  parkingLot: "0305",
  botannBattery: "0306"
};

export const defMenu = {
  name: "概览",
  routerName: "Overview",
  menuIndex: "1-1"
};

// 链账户只有新建(0)，数字资产有0是新建，1是修改，2是发行，资产份额流通有0是授予，1是转让，资产份额活动0是新建，1是修改，2是发行
export const chanelType = [
  { label: "链账户", value: 1, child: [{ label: "新建", value: 0 }] },
  {
    label: "数字资产状态",
    value: 2,
    child: [
      { label: "新建", value: 0 },
      { label: "修改", value: 1 },
      { label: "发行", value: 2 }
    ]
  },
  {
    label: "资产份额流通",
    value: 3,
    child: [
      { label: "授予", value: 3 },
      { label: "转让", value: 4 }
    ]
  },
  {
    label: "资产份额活动",
    value: 4,
    child: [
      { label: "新建", value: 0 },
      { label: "修改", value: 1 },
      { label: "发行", value: 2 }
    ]
  }
];

export const coditonList = [
  { label: "区块高度", value: "blockHeight" },
  { label: "交易哈希", value: "txHash" },
  { label: "渠道编号", value: "appId" },
  { label: "资产编号", value: "assetId" }
];

//0是初始化，1是发行成功，2是失败，3是发行中
export const statusList = [
  { label: "全部", value: undefined },
  { label: "初始化", value: 0 },
  { label: "发行成功", value: 1 },
  { label: "失败", value: 2 },
  { label: "发行中", value: 3 }
];
export const statusMap = {
  0: "初始化",
  1: "发行成功",
  2: "失败",
  3: "发行中"
};
export const txCoditonList = [
  { label: "区块高度", value: "blockHeight" },
  { label: "交易哈希", value: "txHash" },
  { label: "请求编号", value: "requestId" }
];

//交易状态，0是处理中，1是成功，2是失败
export const txStatusList = [
  { label: "全部", value: undefined },
  { label: "上链成功", value: 1 },
  { label: "上链失败", value: 2 },
  { label: "等待中", value: 0 }
];
export const COMMON_MESSAGE = {
  networkErr: "网络异常，请稍后重试~",
  imgErr: "暂无附件可查看"
};

export const ACCOUNT_SCHEMA = {
  type: {
    // 1: "按次计费",
    2: "按周期计费",
    3: "按燃料消耗计费"
  },
  spec: {
    // 1: {
    //   1: "按单次计费 - 2元/次",
    //   2: "按次计费方案A (10,000次 - 1.98万元)",
    //   3: "按次计费方案B (50,000次 - 9.5万元)",
    //   4: "按次计费方案C (100,000次 - 15万元)",
    // },
    3: {
      0: "燃料余额消耗"
    }
  },
  expire: {
    0: "不限",
    6: "6个月",
    12: "12个月",
    24: "24个月"
  },
  timeRange: {
    "24-hour": "近24小时",
    "7-day": "近7天",
    "15-day": "近15天",
    "30-day": "近30天"
  },
  specLimit: {
    1: 0,
    2: 10000,
    3: 50000,
    4: 100000
  },
};

export const orderConditionList = [
  { label: "订单编号", value: "orderId" },
  { label: "渠道编号", value: "appId" },
  { label: "工作台账号", value: "workbenchUser" }
];
export const orderStatusList = [
  { label: "全部", value: undefined },
  { label: "处理中", value: 0},
  { label: "成功", value: 1 },
  { label: "失败", value: 2 },
  { label: "过期", value: 3 },
  { label: "交易关闭", value: 4}
];

export const userConditionList = [
  { label: "客户名称", value: "name" },
  { label: "机构代码", value: "creditCode" },
  { label: "渠道编号", value: "appId" },
  { label: "工作台账号", value: "workbenchAccount" }
];

export const auditConditionList = [
  { label: "全部", value: undefined },
  { label: "已认证", value: "2" },
  { label: "待审核", value: "1" },
  { label: "未认证", value: "0" },
  { label: "未通过", value: "3" },
];

export const indentifyTypeConditionList = [
  { label: "全部", value: undefined },
  { label: "企业法人", value: "0" },
  { label: "代理人", value: "1" },
];

export const enterpriseAuthMap = {
  0: {
    text: '未认证',
    class: 'wating'
  },
  1: {
    text: '待审核',
    class: 'wating'
  },
  2: {
    text: '已认证',
    class: 'success'
  },
  3: {
    text: '未通过',
    class: 'fail'
  },
  4: {
    text: '待审核',
    class: 'wating'
  },
  5: {
    text: '未通过',
    class: 'fail'
  }
}

export const identifyStatusMap = {
  realNameStatus: {
    2: {
      text: '实名',
      class: 'success'
    }
  },
  auditStatus: {
    2: {
      text: '企业',
      class: 'success'
    }
  }
}

export const enterpriseIdentifyMap = {
  0: {
    text: '企业法人'
  },
  1: {
    text: '代理人'
  }
}

export const resultStatusMap = {
  0: {
    text: '处理中',
    class: 'wating',
    noIcon: true,
  },
  1: {
    text: '成功',
    class: 'success',
    noIcon: true,
  },
  2: {
    text: '失败',
    class: 'fail',
    noIcon: true,
  },
  3: {
    text: "过期",
    class: "fail",
    noIcon: true,
  },
  4: {
    text: "交易关闭",
    class: "fail",
    noIcon: true,
  }
}

export const exchangeStatusMap = {
  0: {
    text: '未兑换',
    class: 'wating',
    noIcon: true,
  },
  1: {
    text: '已兑换',
    class: 'success',
    noIcon: true,
  },
  2: {
    text: '已过期',
    class: 'fail',
    noIcon: true,
  },
};

export const voucherConditionList = [
  { label: "兑换券码", value: 'voucherCode' }
];

export const exchangeConditionList = [
  { label: "全部", value: undefined },
  { label: "已兑换", value: 1 },
  { label: "未兑换", value: 0 },
  { label: "已过期", value: 2 }
];


import router from '@/router'
import store from '@/vuex/store'
import { Loading } from 'element-ui'

let loadingInstance
//整体loading
export const showloading = () => {
  let loadOption = {
    fullscreen: true,
    background: 'rgba(0, 0, 0, 0.7)',
    lock: true,
    text: '数据获取中...',
    customClass: 'fullLoading',
    spinner: 'el-icon-loading',
  }
  if (router.currentRoute.name !== 'login' && store.state.fullLoading) {
    loadingInstance = Loading.service(loadOption)
  }
  
}
export const hideloading = () => {
  if (!store.state.fullLoading === false) {
    if(router.currentRoute.name !== 'login' && loadingInstance){
      setTimeout(() => {
       loadingInstance.close()
      }, 100);
    } 
  }
}


<template>
    <section style="margin-top:20px;">
        <div style="width:50%;margin:0 auto">
            <el-alert
            :title="errorContent"
            type="warning"
            :description="`出错代码：${status}`"
            :closable = "false"
            show-icon>
             </el-alert>
        </div>
    </section>
</template>
    
<script> 
    export default {
        data() {
            return {
                status:this.$route.query.status,
                errorContent:"对不起，出错了，强力抢修中!"
            }
        },
        methods: {
        },
        mounted() {
            if(this.status == "403"){
                this.errorContent = "对不起，出错了！您暂无权限";
            }else if((this.status == "500") || (this.status == "502") || (this.status == "504")){
                this.errorContent = "对不起，出错了！服务器正在抢修中";
            }else if(this.status == "404"){
                this.errorContent = "对不起，出错了！页面不存在";
            }
            this.$store.commit('HIDEFULLLOAD', true)
        }
    }
</script>
//test
import API from "@/api/api_login";
export const increment = ({ commit }) => {
  commit("INCREMENT");
};
export const decrement = ({ commit }) => {
  commit("DECREMENT");
};

export function setUserInfo({ commit }, payload) {
  commit("setUserInfo", payload || {});
  API.baseUserInfo().then(({ data }) => {
    const baseUser = Object.assign(data.data || {}, payload || {});
		commit("setUserInfo", baseUser);
		sessionStorage.setItem("userInfo", JSON.stringify(baseUser));
  });
}

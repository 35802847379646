import Vue from "vue";
import Vuex from "vuex";
import * as actions from "./actions";
import * as getters from "./getters";
import { showloading, hideloading } from "./fullLoading";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
// 应用初始状态
const state = {
  fullLoading: false,
  digitalAccountDetail: {}, //数字账户详情
  curTabMenuName: "",
  userInfo: {},
  accountFillInfo: {},
  txDetail:{},//交易流传详情
  assetDetail:{},//资产发行详情
  channelDetail:{},//渠道请求详情
  voucherDesc: {}, // 兑换券详情
  deviceInfo: {
    baseUrl: ""
  }
};

// 定义所需的 mutations
const mutations = {
  SHOWFULLLOAD(state, flag) {
    state.fullLoading = flag;
    showloading();
  },
  HIDEFULLLOAD(state, flag) {
    state.fullLoading = flag;
    hideloading();
  },
  getDigitalAccountDetail(state, payload) {
    state.digitalAccountDetail = payload;
  },
  setTabMenuName(state, payload) {
    state.curTabMenuName = payload;
  },
  setUserInfo(state, payload) {
    state.userInfo = payload;
  },
  setAccountFillInfo(state, payload) {
    state.accountFillInfo = payload;
    sessionStorage.setItem('accountFillInfo', JSON.stringify(state.accountFillInfo));
  },
  setDetail(state, payload) {
    const {name,detailObj} = payload
    state[name] = detailObj;
    // sessionStorage.setItem(`${name}`, JSON.stringify(state[name]));
  },
  setVoucherDesc(state, payload) {
    state.voucherDesc = payload;
    sessionStorage.setItem('voucherDesc', JSON.stringify(payload));
  }
};

// 创建 store 实例
export default new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          // 只储存state中的assessmentData
          digitalAccountDetail: val.digitalAccountDetail
        };
      }
    })
  ]
});
